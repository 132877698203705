import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';
import { NgcCookieConsentConfig, NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-five',
  templateUrl: './home-five.component.html',
  styleUrl: './home-five.component.css'
})
export class HomeFiveComponent  {
  options: NgcCookieConsentConfig;
  private statusChangeSubscription!: Subscription;

  constructor(private cookieService: NgcCookieConsentService,
    private translate: TranslateService,
  ) {

    this.options = {
      cookie: {},
      position: 'bottom',
      theme: 'classic',
      palette: {},
      type: 'info',
      content: {}
    };

    if(localStorage.getItem('cookieEventStatus')) { 
      this.cookieService.destroy(); this.options.enabled = false;
      this.cookieService.init(this.options);
    }
   }
 
   ngOnInit() {
    if(localStorage.getItem('cookieEventStatus')) this.cookieService.fadeOut();
      // localStorage.removeItem('cookieEventStatus')
     
    this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        localStorage.setItem('cookieEventStatus', event.status);
      });
  }

  allFeatures = [
    'period-support',
    'menu-management',
    'restaurant-informations-management',
    'translations',
    'sales-data-analysis',
    'subdomain',
    'professional-domain',
    'receiving-orders',
    'orders-tracking',
    'online-table-reservation',
    'gift-card',
    'online-payement',
    'points-and-rewards',
    'sharing-gifts-and-messages'
  ]
 

  animateCounter(fact: any): void {
    const duration = 4000; 
    const intervalTime = 50; 
    const increment = fact.number / (duration / intervalTime);

    const interval = setInterval(() => {
      fact.displayedNumber += increment;
      if (fact.displayedNumber >= fact.number) {
        fact.displayedNumber = fact.number;
        clearInterval(interval); 
      }
    }, intervalTime);
  }

 
 
  scrollToSection(sectionId: string) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  

 

  // Pricing
  pricing = [
    {
      title: "basic-plan",
      // subTitle: "An affordable option for end-to-end hiring at small companies.",
      currency: "€",
      amount: 19,
      btnClass: "btn btn-dark btn-md btn-default btn-square",
      // btnText: "Get Started",
      features: [
        'menu-management',
        'restaurant-informations-management',
        'translations',
        'sales-data-analysis',
        'period-support',
        // 'receiving-orders',
        // 'orders-tracking',
        'subdomain'
      ]
    },

    {
      title: "standard-plan",
      // subTitle: "An affordable option for end-to-end hiring at small companies.",
      currency: "€",
      amount: 29,
      btnClass: "btn btn-dark btn-md btn-default btn-square",
      // btnText: "Get Started",
      features: [
        'menu-management',
        'restaurant-informations-management',
        'translations',
        'sales-data-analysis',
        'period-support',
        'receiving-orders',
        'orders-tracking',
        'professional-domain',
        'online-table-reservation',
        'gift-card',
        'online-payement'
      ]
    },

    {
      title: "professional-plan",
      // subTitle: "An affordable option for end-to-end hiring at small companies.",
      currency: "€",
      amount: 39,
      btnClass: "btn btn-dark btn-md btn-default btn-square",
      // btnText: "Get Started",
      features: [
        'menu-management',
        'restaurant-informations-management',
        'translations',
        'sales-data-analysis',
        'period-support',
        'receiving-orders',
        'orders-tracking',
        'professional-domain',
        'online-table-reservation',
        'gift-card',
        'online-payement',
        'points-and-rewards',
        'sharing-gifts-and-messages'

      ]
    }
  ];

  //Testimonials
  customOptions: OwlOptions = {
    loop: true,
    dots: true,
    margin:30,
    nav:false,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 1,
    navText: ['', ''],
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      },
      1000:{
          items:3,
      }
    }
  }

  slideStore = [
    {
      title: "David Levine",
      description: "Very much satisfied with the service. Delivered on time and responded to request for modifications within few hours. I recommend Sloganshub for anyone looking for smart business.",
    },

    {
      title: "Michael Kyle",
      description: "I'm very happy with the results! They went above and beyond for me and that is always appreciated! I would highly recommend SH to anybody!",
    },

    {
      title: "Mellissa Christine",
      description: "Great work I got a lot more than what I ordered, they are very legítimas and catchy. I went for one of them for my brand but is always better to have more options.",
    },

    {
      title: "Anne McAdams",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sodales nec nulla ac aliquet. Duis vel nunc eget.",
    }

  ]
 
  // Contact Form
  formData = {
    user_name: '',
    resto_name: '',
    user_email: '',
    user_tel: '',
    message: ''
  };

  // EmailJS service configuration
  private serviceId = 'service_7bhhqag';  // Replace with your EmailJS Service ID
  private templateId = 'template_tlsrmbb';  // Replace with your EmailJS Template ID
  private publicKey = 'Yf0WIE0LRXWn412EF';  // Replace with your EmailJS Public Key

  // Method to send email on form submission
  public sendEmail(event: Event): void {
    event.preventDefault();

 
    // Send email using EmailJS
    emailjs.sendForm(this.serviceId, this.templateId, event.target as HTMLFormElement, this.publicKey)
      .then(
        (response: EmailJSResponseStatus) => { console.log('event.target:',event.target);
          alert('Message sent successfully. Thank you, we will get back to you soon!');
          this.resetForm();
          this.scrollToSection('about')
        },
        (error: EmailJSResponseStatus) => {
          console.error('Failed to send message. Error:', error.text);
          alert('Failed to send the message. Please try again later.');
        }
      );
  }

  // Method to reset the form after submission
  private resetForm(): void {
    this.formData = {
      user_name: '',
      resto_name: '',
      user_tel: '',
      user_email: '',
      message: ''
    };
  }
 
  

  // Video Popup
	isOpen = false;
  openPopup(): void {
      this.isOpen = true;
  }
  closePopup(): void {
      this.isOpen = false;
  }
 

  

  ngOnDestroy() {
    this.statusChangeSubscription.unsubscribe();
  }

}
